<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-0">
              {{ $t("RESELLER_SERVICES.RESELLER_SERVICES_LIST") }}
            </h3>
          </div>
          <div class="col-6 text-right">
            <base-button
              class="elite-button add"
              icon
              size="sm"
              @click="openResellerServiceCreateModal"
              v-if="$currentUserCan($permissions.PERM_CREATE_RESELLER_SERVICES)"
            >
              <span class="btn-inner--icon">
                <i class="far fa-plus-circle"></i>
              </span>
              <span class="btn-inner--text">
                {{ $t("RESELLER_SERVICES.ADD_RESELLER_SERVICE") }}
              </span>
            </base-button>
            <notification-subscription
              v-if="$currentUserCan($permissions.PERM_VIEW_RESELLER_SERVICES)"
              :objectType="'reseller-services'"
              :events="{
                CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
                UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
              }"
            />
          </div>
        </div>
      </div>

      <reseller-service-list-table
        @onViewResellerService="openResellerServiceViewModal"
        @onEditResellerService="openResellerServiceEditModal"
        @onDeleteResellerService="deleteResellerService"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewResellerServiceModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewResellerServiceModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'RESELLER_SERVICE_VIEW'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("RESELLER_SERVICES.VIEW_RESELLER_SERVICE") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <notification-subscription
                v-if="openResellerService"
                :objectType="'reseller-services'"
                :objectId="openResellerService.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />

              <button class="close" @click="closeResellerServiceModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>

          <div class="resizable-wrapper-content-body">
            <view-reseller-service-page
              v-if="openResellerService"
              :resellerServiceId="openResellerService.id"
              @onEditResellerService="openResellerServiceEditModal"
              @onDeleteResellerService="deleteResellerService"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isEditResellerServiceModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditResellerServiceModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'RESELLER_SERVICE'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("RESELLER_SERVICES.EDIT_RESELLER_SERVICE") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button
                  class="view"
                  @click="openResellerServiceViewModal(openResellerService)"
                >
                  <i class="fal fa-eye"></i>
                  <span>{{ $t("COMMON.VIEW") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closeResellerServiceModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-reseller-service-page
              v-if="openResellerService"
              :resellerServiceId="openResellerService.id"
              @onViewResellerService="openResellerServiceViewModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddResellerServiceModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddResellerServiceModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'RESELLER_SERVICE'"
        >
          <div class="resizable-wrapper-content-header">
            <h1>
              {{ $t("RESELLER_SERVICES.ADD_RESELLER_SERVICE") }}
            </h1>
            <button class="close" @click="closeResellerServiceModal">
              <i class="fal fa-times"></i>
            </button>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-reseller-service-page
              @onViewResellerService="openResellerServiceViewModal"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import requestErrorMixin from "@/mixins/request-error-mixin";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import ResellerServiceListTable from "./partials/ResellerServiceListTable.vue";
import EditResellerServicePage from "./components/EditResellerServiceComponent.vue";
import AddResellerServicePage from "./components/AddResellerServiceComponent.vue";
import ViewResellerServicePage from "./components/ViewResellerServiceComponent.vue";

export default {
  layout: "DashboardLayout",

  components: {
    ResellerServiceListTable,
    NotificationSubscription,
    EditResellerServicePage,
    AddResellerServicePage,
    ViewResellerServicePage,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {},

  watch: {},

  data() {
    const locationId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewResellerServiceModalOpened = false;
    let isEditResellerServiceModalOpened = false;
    let isAddResellerServiceModalOpened = false;
    let openResellerService = null;
    if (locationId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewResellerServiceModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditResellerServiceModalOpened = true;
      }
      openResellerService = { id: locationId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddResellerServiceModalOpened = true;
    }
    return {
      isViewResellerServiceModalOpened: isViewResellerServiceModalOpened,
      isEditResellerServiceModalOpened: isEditResellerServiceModalOpened,
      isAddResellerServiceModalOpened: isAddResellerServiceModalOpened,
      openResellerService: openResellerService,
      renderKey: 1,
    };
  },

  methods: {
    openResellerServiceCreateModal() {
      this.closeResellerServiceModal();
      this.isAddResellerServiceModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List ResellerServices",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },
    openResellerServiceViewModal(resellerservice, reRender = false) {
      this.closeResellerServiceModal();
      this.openResellerService = resellerservice;
      this.isViewResellerServiceModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List ResellerServices",
          query: {
            id: this.openResellerService.id,
            action: QUERY_ACTIONS_VIEW,
          },
        }).href
      );
    },
    openResellerServiceEditModal(resellerservice) {
      this.closeResellerServiceModal();
      this.openResellerService = resellerservice;
      this.isEditResellerServiceModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List ResellerServices",
          query: {
            id: this.openResellerService.id,
            action: QUERY_ACTIONS_EDIT,
          },
        }).href
      );
    },
    closeResellerServiceModal() {
      this.isAddResellerServiceModalOpened = false;
      this.isViewResellerServiceModalOpened = false;
      this.isEditResellerServiceModalOpened = false;
      this.openResellerService = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List ResellerServices",
          query: {},
        }).href
      );
    },

    async deleteResellerService(resellerservice) {
      const confirmation = await swal.fire({
        text: this.$t("RESELLER_SERVICES.DELETE_THIS_RESELLER_SERVICE"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch(
            "resellerServices/destroy",
            resellerservice.id
          );
          this.renderKey++;
          this.closeResellerServiceModal();
          this.$notify({
            type: "success",
            message: this.$t("RESELLER_SERVICES.RESELLER_SERVICE_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
